import axios from "axios";

const END_POINT = '/api/deliveries';

const fetchDeliveries = (query) =>  axios.get(END_POINT + query);
const fetchDeliveriesForPackaging = (query) =>  axios.get(END_POINT + '/get-packaging-labels-deliveries' + query);
const fetchDeliveriesForMealTops = (query) =>  axios.get(END_POINT + '/get-meal-tops-deliveries' + query);
const createDeliveries = (data) =>  axios.post(END_POINT, data);
const deleteDeliveries =  (date) => axios.delete(`${END_POINT}?date=${date}`)

export {
    fetchDeliveries,
    createDeliveries,
    deleteDeliveries,
    fetchDeliveriesForPackaging,
    fetchDeliveriesForMealTops
}
