import { fetchPlans } from "../api/plans";

const SET_PLANS = 'SET_PLANS';

export default {
    namespaced: true,
    state: {
        plans: []
    },
    getters: {
        plans(state) {
            return state.plans
        }
    },
    mutations: {
        SET_PLANS (state, value) {
            state.plans = value
        }
    },
    actions: {
        async fetchPlans({commit}, query) {
            try {
                const response = await fetchPlans(query);
                commit(SET_PLANS, response.data)

            } catch (error) {
                console.log(error);
            }
        }
    }
}
