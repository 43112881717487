import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

VueRouter.prototype.openNewTab = function (routeObject) {
    const {href} = this.resolve(routeObject)
    window.open(href, '_blank')
}

Vue.use(VueRouter)

const PageNotFound = () => import('../components/common/PageNotFound')

/* Guest Component */
const Login = () => import('../components/pages/Login.vue')
/* Guest Component */

/* layouts */
const MainLayout = () => import('../components/layouts/Main.vue')
/* layouts */

/* Authenticated Component */
const Dashboard = () => import('../components/pages/Dashboard.vue')
const Deliveries = () => import('../components/pages/deliveries/Deliveries.vue')
const Meals = () => import('../components/pages/meals/Meals')
const AddMeal = () => import('../components/pages/meals/AddMeal')
const PendingCancellation = () => import('../components/pages/subscriptions/PendingCancellation')
const CheckActive = () => import('../components/pages/subscriptions/CheckActive')
const CheckOnHold = () => import('../components/pages/subscriptions/CheckOnHold')
const PackagingLabels = () => import('../components/pages/labels/PackagingLabels')
const MealTopsLabels = () => import('../components/pages/labels/MealTopsLabels')
const MealPlanStats = () => import('../components/pages/stats/MealPlanStats')
const EditSubscription = () => import('../components/pages/subscriptions/EditSubscription')
const Subscriptions = () => import('../components/pages/subscriptions/Subscriptions')
const FailedDelivery = () => import('../components/pages/failed_delivery/FailedDelivery')

/* Authenticated Component */


const Routes = [
    {
        name:"login",
        path:"/login",
        component:Login,
        meta:{
            middleware:"guest",
            title:`Login`
        }
    },
    {
        path:"/",
        component: MainLayout,
        meta:{
            middleware:"auth"
        },
        children:[
            {
                name:"dashboard",
                path: '/',
                component: Dashboard,
                meta:{
                    title:`Dashboard`
                }
            },
            {
                name:"deliveries",
                path: '/deliveries',
                component: Deliveries,
                meta:{
                    title:`Deliveries`
                }
            },
            {
                name:"meals",
                path: '/meals',
                component: Meals,
                meta:{
                    title:`Meals`
                }
            },
            {
                name:"add-meal",
                path: '/add-meal',
                component: AddMeal,
                meta:{
                    title:`Add Meal`
                }
            },
            {
                name:"pending-cancellation",
                path: '/pending-cancellation',
                component: PendingCancellation,
                meta:{
                    title:`Pending Cancellation`
                }
            },
            {
                name:"check-active",
                path: '/check-active',
                component: CheckActive,
                meta:{
                    title:`Check Active`
                }
            },
            {
                name:"check-on-hold",
                path: '/check-on-hold',
                component: CheckOnHold,
                meta:{
                    title:`Check On hold`
                }
            },
            {
                name:"packaging-labels",
                path: '/packaging-labels',
                component: PackagingLabels,
                meta:{
                    title:`Packaging labels`
                }
            },
            {
                name:"meal-tops-labels",
                path: '/meal-tops-labels',
                component: MealTopsLabels,
                meta:{
                    title:`Meal tops labels`
                }
            },
            {
                name:"meal-plan-stats",
                path: '/meal-plan-stats',
                component: MealPlanStats,
                meta:{
                    title:`Meal plan stats`
                }
            },
            {
                name:"subscriptions",
                path: '/subscriptions',
                component: Subscriptions,
                meta:{
                    title:`Subscriptions`
                }
            },
            {
                name:"edit-subscription",
                path: '/subscription/:id',
                component: EditSubscription,
                meta:{
                    title:`Edit subscription`
                }
            },
            {
                name:"FailedDelivery",
                path: '/failed-delivery',
                component: FailedDelivery,
                meta:{
                    title:`Failed Generated Delivery`
                }
            },
            { path: "*", component: PageNotFound }
        ]
    },
]

const router  = new VueRouter({
    mode: 'history',
    routes: Routes,
    linkExactActiveClass: 'active'
})

router.beforeEach((to, from, next) => {
    document.title = `${to.meta.title} - ${process.env.MIX_APP_NAME}`
    if (to.meta.middleware === "guest") {
        if (store.state.auth.authenticated) {
            next({name:"dashboard"})
        }
        next()
    } else {
        if (store.state.auth.authenticated) {
            next()
        } else {
            next({name:"login"})
        }
    }
})

export default router
