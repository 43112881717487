import {fetchMeals} from "../api/meals.api";
import {fetchMeal} from "../api/meals.api";
import {fetchPlansAndDislikes} from "../api/meals.api";
import {storeMeal} from "../api/meals.api";
import {deleteMeal} from "../api/meals.api";
import {updateMeal} from "../api/meals.api";

const SET_MEALS = 'SET_MEALS';
const SET_MEAL = 'SET_MEAL';
const SET_VIEW_MODEL = 'SET_VIEW_MODEL';
const SET_MEAL_FORM_ERRORS = 'SET_MEAL_FORM_ERRORS';

export default {
    namespaced: true,
    state: {
        meals: [],
        meal: null,
        viewModel: [],
        mealFormErrors: null
    },
    getters: {
        meals(state) {
            return state.meals
        },
        meal(state) {
            return state.meal
        },
        viewModel(state) {
            return state.viewModel
        },
        mealFormErrors(state) {
            return state.mealFormErrors
        }
    },
    mutations: {
        SET_MEALS (state, value) {
            state.meals = value
        },
        SET_MEAL (state, value) {
            state.meal = value
        },
        SET_VIEW_MODEL (state, value) {
            state.viewModel = value
        },
        SET_MEAL_FORM_ERRORS (state, value) {
            state.mealFormErrors = value
        }
    },
    actions: {
        async fetchMeals({commit}, query) {
            try {
                const meals = await fetchMeals(query);
                commit(SET_MEALS, meals.data)

            } catch (error) {
                console.log(error);
            }
        },
        async fetchMeal({commit}, id) {
            try {
                const meal = await fetchMeal(id);
                commit(SET_MEAL, meal.data)

            } catch (error) {
                console.log(error);
            }
        },
        async fetchViewModel({commit}) {
            try {
                const viewModel = await fetchPlansAndDislikes();
                commit(SET_VIEW_MODEL, viewModel.data)

            } catch (error) {
                console.log(error);
            }
        },

        async storeMeal({commit}, data) {
            try {
                commit(SET_MEAL_FORM_ERRORS, null)
                const meal = await storeMeal(data);
                commit(SET_MEAL, meal.data)

            } catch (error) {
                commit(SET_MEAL_FORM_ERRORS, error.response.data.errors)
                console.log(error.response);
            }
        },

        async updateMeal({commit}, { id ,data }) {
            try {
                commit(SET_MEAL_FORM_ERRORS, null)
                const meal = await updateMeal(id, data);
                commit(SET_MEAL, meal.data)
            } catch (error) {
                commit(SET_MEAL_FORM_ERRORS, error.response.data.errors)
                console.log(error.response);
            }
        },
        async deleteMeal({commit}, id) {
            try {
               await deleteMeal(id);

            } catch (error) {
                console.log(error.response);
            }
        }
    }
}
