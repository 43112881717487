import {
    createDeliveries,
    fetchDeliveries,
    deleteDeliveries,
    fetchDeliveriesForPackaging,
    fetchDeliveriesForMealTops
} from "../api/deliveries.api";

const SET_DELIVERIES = 'SET_DELIVERIES';
const SET_PACKAGING_DELIVERIES = 'SET_PACKAGING_DELIVERIES';
const SET_PACKAGING_ERROR = 'SET_PACKAGING_ERROR';
const SET_MEAL_TOPS_ERROR = 'SET_MEAL_TOPS_ERROR';
const SET_MEAL_TOPS_DELIVERIES = 'SET_MEAL_TOPS_DELIVERIES';

export default {
    namespaced: true,
    state: {
        deliveries: [],
        packagingDeliveries: [],
        mealTopsDeliveries: [],
        packagingError: null,
        mealTopsError: null,
    },
    getters: {
        deliveries(state) {
            return state.deliveries
        },
        packagingDeliveries(state) {
            return state.packagingDeliveries
        },
        mealTopsDeliveries(state) {
            return state.mealTopsDeliveries
        },
        packagingError(state) {
            return state.packagingError
        },
        mealTopsError(state) {
            return state.mealTopsError
        }
    },
    mutations: {
        SET_DELIVERIES (state, value) {
            state.deliveries = value
        },
        SET_PACKAGING_DELIVERIES (state, value) {
            state.packagingDeliveries = value
        },
        SET_MEAL_TOPS_DELIVERIES (state, value) {
            state.mealTopsDeliveries = value
        },
        SET_PACKAGING_ERROR (state, value) {
            state.packagingError = value
        },
        SET_MEAL_TOPS_ERROR (state, value) {
            state.mealTopsError = value;
        }
    },
    actions: {
        async fetchDeliveries({commit}, query) {
            try {
                const deliveries = await fetchDeliveries(query);
                commit(SET_DELIVERIES, deliveries.data)

            } catch (error) {
                console.log(error);
            }
        },

        async fetchDeliveriesForPackaging({commit}, query) {
            try {
                commit(SET_PACKAGING_ERROR, null)
                const deliveries = await fetchDeliveriesForPackaging(query);
                commit(SET_PACKAGING_DELIVERIES, deliveries.data)
            } catch (error) {
                commit(SET_PACKAGING_ERROR, error.response.data.message)
                console.log(error.response.data.message);
            }
        },

        async fetchDeliveriesForMealTops({commit}, query) {
            try {
                const deliveries = await fetchDeliveriesForMealTops(query);
                commit(SET_MEAL_TOPS_DELIVERIES, deliveries.data)
            } catch (error) {
                commit(SET_MEAL_TOPS_ERROR, error.response.data.message)
                console.log(error);
            }
        },

        async createDeliveries({commit}, data) {
            try {
                await createDeliveries(data);
            } catch (error) {
                console.log(error);
            }
        },

        async deleteDeliveries({commit}, date) {
            try {
                await deleteDeliveries(date);
            } catch (error) {
                console.log(error)
            }
        }
    }
}
