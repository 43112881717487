
export default {
    namespaced: true,
    state:{
        loading: false,
    },
    getters:{
        loading(state){
            return state.loading
        }
    },
    mutations:{
        SET_LOADING (state, value) {
            state.loading = value
        },
    },
    actions:{
        setLoading({commit}, value){
            commit('SET_LOADING', value)
        }
    }
}
