const buildPagination = (page, last_page) => {

    let out = [];

    for(let i = page - 2; i <= (page + 2); i++) {

        if(i > last_page) {
            break;
        }

        if( i <= 0) {
            continue;
        }

        out.push(i)

    }

    if(last_page - out[out.length - 1] >= 1) {

        if(last_page - out[out.length - 1] >= 2) {
            out.push(null)
        }

        out.push(last_page)

    }

    if(out.length) {

        if(out[0] >= 2) {

            if(out[0] > 2){

                out.unshift(null);

            }
            out.unshift(1)

        }

    }

    return out;


};



exports.buildPagination = buildPagination;
