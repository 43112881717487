import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import auth from './auth'
import common from "./common";
import deliveries from "./deliveries";
import meals from "./meals";
import subscriptions from "./subscriptions";
import plans from "./plans";

Vue.use(Vuex)

export default new Vuex.Store({
    plugins:[
        createPersistedState({ paths: ['auth', 'common'] })
    ],
    modules:{
        auth,
        common,
        deliveries,
        meals,
        subscriptions,
        plans
    }
})
