import { fetchSubscription} from "../api/subscriptions.api";
import { fetchSubscriptions} from "../api/subscriptions.api";
import { syncSubscription } from '../api/subscriptions.api';
import { syncSubscriptions } from '../api/subscriptions.api';

const SET_SUBSCRIPTION = 'SET_SUBSCRIPTION';
const SET_SUBSCRIPTIONS = 'SET_SUBSCRIPTIONS';

export default {
    namespaced: true,
    state: {
        subscriptions: [],
        subscription: {},
    },
    getters: {
        subscription(state) {
            return state.subscription;
        },
        subscriptions(state) {
            return state.subscriptions
        }
    },
    mutations: {
        SET_SUBSCRIPTION(state, value) {
            state.subscription = value;
        },
        SET_SUBSCRIPTIONS(state, value) {
            state.subscriptions = value;
        }
    },
    actions: {
        async fetchSubscription({commit}, id) {
            try {
                const subscription = await fetchSubscription(id);
                commit(SET_SUBSCRIPTION, subscription.data)

            } catch (error) {
                console.log(error);
            }
        },

        async fetchSubscriptions({commit}, query) {
            try {
                const subscriptions = await fetchSubscriptions(query);
                commit(SET_SUBSCRIPTIONS, subscriptions.data)

            } catch (error) {
                console.log(error);
            }
        },

        async syncSubscription({commit}, wpSubId) {
            try {
                await syncSubscription(wpSubId);

            } catch (error) {
                console.log(error);
            }
        },

        async syncSubscriptions({commit}, wpSubId) {
            try {
                await syncSubscriptions(wpSubId);

            } catch (error) {
                console.log(error);
            }
        }
    }
}
