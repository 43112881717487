/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import FailedDelivery from "./components/pages/failed_delivery/FailedDelivery";

require('./bootstrap');

import Vue from 'vue';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import Notifications from 'vue-notification';
import Multiselect from 'vue-multiselect';

Vue.component('loading-overlay', Loading);
Vue.use(Notifications);
Vue.component('multiselect', Multiselect);
Vue.component('failed-delivery', FailedDelivery);

import router from './router'
import store from './store'

const app = new Vue({
    el: '#app',
    router:router,
    store:store
});
