<template>

    <div>
        <div class="row mt-2">

            <div class="card card-primary card-outline">
                <div class="card-body">
                    <div class="row">
                        <!--                        <div class="col-md-4">-->
                        <!--                            <div class="form-group">-->
                        <!--                                <label for="searchInput">Search by meal name</label>-->
                        <!--                                <input id="searchInput" ref="searchInput" class="form-control input-height" placeholder="Name" v-model="keyword" type="text">-->
                        <!--                            </div>-->
                        <!--                        </div>-->
                        <!--                        <div class="col-md-4">-->
                        <!--                            <div class="form-group">-->
                        <!--                                <label for="mealPlanSelect">Meal Plan</label>-->
                        <!--                                <multiselect id="mealPlanSelect" v-model="selectedPlan" :options="plans" :close-on-select="true"  placeholder="Select meal plan" label="name" track-by="id"></multiselect>-->
                        <!--                            </div>-->
                        <!--                        </div>-->
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="delivery-date">Delivery date</label>
                                <input id="delivery-date" class="form-control input-height" v-model="date" type="date">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="rows" class="col-md-12">
                <div class="table-responsive">
                    <table class="table table-bordered table-hover mg-b-0">
                        <thead>
                        <tr>
                            <th>Subscription ID</th>
                            <th>Customer name</th>
                            <th>Date for delivery</th>
                            <th>Create attempt</th>
                            <th>Error</th>
                            <th><button class="btn btn-danger" @click="archiveAll">Archive ALL</button></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="row in rows">
                            <td>
                                <router-link :to="{name: 'edit-subscription', params: { id: row.wp_sub_id}}">
                                    {{ row.wp_sub_id }}
                                </router-link>
                            </td>
                            <td>{{ row.subscription.name }}</td>
                            <td>{{ row.delivery_date }}</td>
                            <td>{{ row.created_at }}</td>
                            <td>{{ row.exception_message }}</td>
                            <th>
                                <button class="btn btn-outline-danger" @click="archive(row.id)">Archive</button>
                            </th>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div v-if="rows" class="row">

            <!--            <div class="col-md-1">-->
            <!--                <select v-model="this.pageSize" @change="handlePageSizeChange" class="form-select" aria-label="Select page size">-->
            <!--                    <option v-for="size in pageSizes">{{ size }}</option>-->
            <!--                </select>-->
            <!--            </div>-->

                        <div class="col-md-11">
                            <ul class="pagination pagination-sm m-0 float-right">
                                <li v-if="rows && page - 1" class="page-item"><a class="page-link" href="javascript:void(0)" @click="getDeliveries(--page)"><<</a></li>
                                <li v-for="i in links" :class="'page-item' + (i === page ? ' active' : '')">
                                    <a v-if="i" class="page-link" href="javascript:void(0)" @click="getDeliveries(i)">{{i}}</a>
                                    <a v-else class="page-link disabled">...</a>
                                </li>
                                <li v-if="rows && page + 1 <= last_page" class="page-item"><a class="page-link" href="javascript:void(0)" @click="getDeliveries(++page)">>></a></li>
                            </ul>
                        </div>
        </div>
    </div>

</template>


<script>
import {buildPagination} from "../../../helpers/paginator";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "FailedDelivery",
    data() {
        return {
            rows: [],
            page: 1,
            date: null,
            last_page: 1,
            pageSize: 30,
            pageSizes: [30, 50, 100],
            links: [],
        }
    },

    created() {
        this.getDeliveries();
    },
    computed: {
        ...mapGetters({
            loading: 'common/loading'
        }),
    },
    methods: {
        ...mapActions({
            setLoading: 'common/setLoading'
        }),
        getDeliveries(page=1) {
            this.setLoading(true);

            let data_param = {};

            if (this.date) {
                data_param.date = this.date
            }

            data_param.page_size = this.pageSize
            data_param.page = page
            axios.get('/api/failed-generated-delivery', {
                params: data_param,
            }).then(response => {
                this.rows = response.data.data
                this.page = response.data.current_page;
                this.last_page = response.data.last_page;
                this.total = response.data.total
                this.links = buildPagination(this.page, this.last_page);
            }).catch(error => {
                console.log(error)
                this.setLoading(false);

            });

            this.setLoading(false);
        },
        archive(id){
            axios.patch('/api/failed-generated-delivery', {
                id: id,
                archived: 1
            }).then(response => {
                // this.rows = []
                this.getDeliveries()
                this.$parent.getUnarchived();
            }).catch(error => {
                console.log(error.response.data)
            });
        },
        archiveAll() {
            axios.post('/api/failed-generated-delivery/archive-all').then(response => {
                // this.rows = []
                this.getDeliveries()
                this.$parent.getUnarchived();
            }).catch(error => {
                console.log(error.response.data)
            });
        }
    },
    watch: {
        date: function (value) {
            this.getDeliveries()
        }
    },
}
</script>

<style scoped>
.input-height {
    height: 42px;
}
</style>
