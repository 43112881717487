const END_POINT = '/api/meals';


const fetchMeals = (query) =>  axios.get(END_POINT + query);
const fetchMeal = (id) =>  axios.get(END_POINT + '/getById/' + id);
const fetchPlansAndDislikes = () => axios.get(END_POINT + '/get-plans-and-dislikes');
const updateMeal = (id, data) => axios.put(END_POINT + '/' + id, data);
const storeMeal = (meal) => axios.post(END_POINT, meal);
const deleteMeal = (id) => axios.delete(END_POINT + '/' + id);

export {
    fetchMeals,
    fetchMeal,
    fetchPlansAndDislikes,
    updateMeal,
    storeMeal,
    deleteMeal
}
