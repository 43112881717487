const END_POINT = '/api/subscriptions';

const fetchSubscription = (id) =>  axios.get(END_POINT + '/getById/' + id);
const fetchSubscriptions = (query) =>  axios.get(END_POINT + query);
const syncSubscription = (wpSubId) =>  axios.get(END_POINT + '/' + wpSubId);
const syncSubscriptions = () =>  axios.get(END_POINT + '/sync-all');


export {
    fetchSubscription,
    syncSubscription,
    fetchSubscriptions,
    syncSubscriptions
}
